import React from 'react'
import loadable from '@loadable/component'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'

import { InsuranceType } from 'types'

import { isProductiony } from 'utils/deployment'
import isServer from 'utils/isServer'

import {
  BlogListRouteMatchProps,
  BlogPostRouteMatchProps,
  CoverageLevelsRouteMatchProps,
  DynamicQuoteRoute,
  ExtendedStaticContext,
} from './types'

const Http404 = loadable(() => import('screens/404').catch(() => () => null))
const SiteMap = loadable(() => import('screens/SiteMap').catch(() => () => <Http404 />))

const Home = loadable(() => import('screens/Home').catch(() => () => <Http404 />))
const AboutUs = loadable(() => import('screens/AboutUs').catch(() => () => <Http404 />))
const Careers = loadable(() => import('screens/AboutUs/Careers').catch(() => () => <Http404 />))
const OurStory = loadable(() => import('screens/AboutUs/OurStory').catch(() => () => <Http404 />))
const OurTeam = loadable(() => import('screens/AboutUs/OurTeam').catch(() => () => <Http404 />))
const Press = loadable(() => import('screens/AboutUs/Press').catch(() => () => <Http404 />))
const PressRelease001 = loadable(() => import('screens/AboutUs/Press/001').catch(() => () => <Http404 />))
const News = loadable(() => import('screens/AboutUs/News').catch(() => () => <Http404 />))
const Franchising = loadable(() => import('screens/Franchising').catch(() => () => <Http404 />))
const FranchisingInquire = loadable(() => import('screens/Franchising/Inquire').catch(() => () => <Http404 />))
const Support = loadable(() => import('screens/Support').catch(() => () => <Http404 />))
const Contact = loadable(() => import('screens/Contact').catch(() => () => <Http404 />))
const Products = loadable(() => import('screens/Products').catch(() => () => <Http404 />))
const ProductsAutoInsurance = loadable(() => import('screens/Products/AutoInsurance').catch(() => () => <Http404 />))
const ProductsHomeownersInsurance = loadable(() => import('screens/Products/HomeownersInsurance').catch(() => () => <Http404 />))
const ProductsLifeInsurance = loadable(() => import('screens/Products/LifeInsurance').catch(() => () => <Http404 />))
const ProductsHealthInsurance = loadable(() => import('screens/Products/HealthInsurance').catch(() => () => <Http404 />))
const ProductsMedicareInsurance = loadable(() => import('screens/Products/MedicareInsurance').catch(() => () => <Http404 />))
const ProductsDisabilityInsurance = loadable(() => import('screens/Products/DisabilityInsurance').catch(() => () => <Http404 />))
const ProductsBusinessInsurance = loadable(() => import('screens/Products/BusinessInsurance').catch(() => () => <Http404 />))
const ProductsInvestmentsRetirement = loadable(() => import('screens/Products/InvestmentsRetirement').catch(() => () => <Http404 />))
const ProductsGroupInsurance = loadable(() => import('screens/Products/GroupInsurance').catch(() => () => <Http404 />))
const ProductsGroupInsuranceQuote = loadable(() => import('screens/Products/GroupInsurance/Quote').catch(() => () => <Http404 />))
const AskQuestion = loadable(() => import('screens/Support/AskQuestion').catch(() => () => <Http404 />))
const Carriers = loadable(() => import('screens/Carriers').catch(() => () => <Http404 />))

const AgencyQuoter = loadable(() => import('screens/AgencyQuoter').catch(() => () => <Http404 />))
const SuggestInsurance = loadable(() => import('screens/SuggestInsurance').catch(() => () => <Http404 />))
const SuggestInsuranceSuccess = loadable(() => import('screens/SuggestInsurance/Success').catch(() => () => <Http404 />))

const QuotingStart = loadable(() => import('screens/Quoting').catch(() => () => <Http404 />))
const QuotingZipCode = loadable(() => import('screens/Quoting/ZipCode').catch(() => () => <Http404 />))
const QuotingInsuranceTypes = loadable(() => import('screens/Quoting/InsuranceTypes').catch(() => () => <Http404 />))
const QuotingEmail = loadable(() => import('screens/Quoting/Email').catch(() => () => <Http404 />))
const QuotingAutoVehicles = loadable(() => import('screens/Quoting/Auto/Vehicles').catch(() => () => <Http404 />))
const QuotingAutoVehicle = loadable(() => import('screens/Quoting/Auto/Vehicle').catch(() => () => <Http404 />))
const QuotingAutoDrivers = loadable(() => import('screens/Quoting/Auto/Drivers').catch(() => () => <Http404 />))
const QuotingAutoDriver = loadable(() => import('screens/Quoting/Auto/Driver').catch(() => () => <Http404 />))
const QuotingAutoIncidents = loadable(() => import('screens/Quoting/Auto/Incidents').catch(() => () => <Http404 />))
const QuotingAutoIncident = loadable(() => import('screens/Quoting/Auto/Incident').catch(() => () => <Http404 />))
const QuotingAutoMissingDriver = loadable(() => import('screens/Quoting/Auto/MissingDriver').catch(() => () => <Http404 />))
const QuotingMissingIncidentTypeA = loadable(() => import('screens/Quoting/Auto/MissingIncident/TypeA').catch(() => () => <Http404 />))
const QuotingMissingIncidentTypeB = loadable(() => import('screens/Quoting/Auto/MissingIncident/TypeB').catch(() => () => <Http404 />))
const AutoAdditionalInfo = loadable(() => import('screens/Quoting/Auto/AdditionalInfo').catch(() => () => <Http404 />))
const QuotingCoverageLevels = loadable(() => import('screens/Quoting/CoverageLevels').catch(() => () => <Http404 />))
const QuotingCoverageLevel = loadable(() => import('screens/Quoting/CoverageLevel').catch(() => () => <Http404 />))
const QuotingPolicy = loadable(() => import('screens/Quoting/Policy').catch(() => () => <Http404 />))
const QuotingRateCallOne = loadable(() => import('screens/Quoting/RateCalls/1').catch(() => () => <Http404 />))
const QuotingRateCallOneSuccess = loadable(() => import('screens/Quoting/RateCalls/1/Success').catch(() => () => <Http404 />))
const QuotingRateCallOnePointFive = loadable(() => import('screens/Quoting/RateCalls/1.5').catch(() => () => <Http404 />))
const QuotingRateCallLoadingTypeA = loadable(() => import('screens/Quoting/RateCalls/Loading/TypeA').catch(() => () => <Http404 />))
const QuotingRateCallLoadingTypeB = loadable(() => import('screens/Quoting/RateCalls/Loading/TypeB').catch(() => () => <Http404 />))
const QuotingRateCallLoadingTypeC = loadable(() => import('screens/Quoting/RateCalls/Loading/TypeC').catch(() => () => <Http404 />))
const QuotingHomeAddress = loadable(() => import('screens/Quoting/Home/Address').catch(() => () => <Http404 />))
const QuotingHomeAbout = loadable(() => import('screens/Quoting/Home/About').catch(() => () => <Http404 />))
const QuotingPricing = loadable(() => import('screens/Quoting/Pricing').catch(() => () => <Http404 />))
const QuotingSignature = loadable(() => import('screens/Quoting/Signature').catch(() => () => <Http404 />))
const QuotingSignatureSuccess = loadable(() => import('screens/Quoting/Signature/Success').catch(() => () => <Http404 />))
const QuotingHealth = loadable(() => import('screens/Quoting/Health').catch(() => () => <Http404 />))
const QuotingLife = loadable(() => import('screens/Quoting/Life').catch(() => () => <Http404 />))
const QuotingMedicare = loadable(() => import('screens/Quoting/Medicare').catch(() => () => <Http404 />))

const Storefronts = loadable(() => import('screens/Storefronts').catch(() => () => <Http404 />))
const StorefrontsBeaverton = loadable(() => import('screens/Storefronts/Beaverton').catch(() => () => <Http404 />))
const StorefrontsEugene = loadable(() => import('screens/Storefronts/Eugene').catch(() => () => <Http404 />))
const StorefrontsGrantsPass = loadable(() => import('screens/Storefronts/GrantsPass').catch(() => () => <Http404 />))
const StorefrontsLasVegas = loadable(() => import('screens/Storefronts/LasVegas').catch(() => () => <Http404 />))
const StorefrontsMedford = loadable(() => import('screens/Storefronts/Medford').catch(() => () => <Http404 />))
const Privacy = loadable(() => import('screens/Privacy').catch(() => () => <Http404 />))
const Terms = loadable(() => import('screens/Terms').catch(() => () => <Http404 />))

const BlogLanding = loadable(() => import('screens/Blog/Landing').catch(() => () => <Http404 />))
const BlogList = loadable(() => import('screens/Blog/List').catch(() => () => <Http404 />))
const BlogPost = loadable(() => import('screens/Blog/Post').catch(() => () => <Http404 />))

/* eslint-disable react/display-name */
const dynamicQuoteRoutes: DynamicQuoteRoute[] = Object.entries({
  '/quote/new': { component: QuotingStart },
  '/quote/new/zip': { component: QuotingZipCode },
  '/quote/new/insurance-types': { component: QuotingInsuranceTypes },
  '/quote/new/email': { component: QuotingEmail },

  // Auto flow screens
  '/quote/new/auto': { render: (): React.ReactElement => <Redirect to='/quote/new/auto/vehicles' /> },
  '/quote/new/auto/vehicles': { component: QuotingAutoVehicles },
  '/quote/new/auto/vehicle/:uuid?': { component: QuotingAutoVehicle },
  '/quote/new/auto/drivers': { component: QuotingAutoDrivers },
  '/quote/new/auto/driver/:uuid?/:modifier?': { component: QuotingAutoDriver },
  '/quote/new/auto/incidents': { component: QuotingAutoIncidents },
  '/quote/new/auto/incident/:uuid?': { component: QuotingAutoIncident },
  '/quote/new/auto/policy': { render: (): React.ReactElement => <QuotingPolicy insuranceType={InsuranceType.auto} /> },
  '/quote/new/auto/coverage-levels': { render: (): React.ReactElement => <QuotingCoverageLevels insuranceType={InsuranceType.auto} /> },
  '/quote/new/auto/coverage-levels/:id/edit': { render: ({ match: { params: { id } } }: CoverageLevelsRouteMatchProps): React.ReactElement => <QuotingCoverageLevel insuranceType={InsuranceType.auto} id={id} /> },
  '/quote/new/auto/rate-calls/1': { render: (): React.ReactElement => <QuotingRateCallOne insuranceType={InsuranceType.auto} /> },
  '/quote/new/auto/rate-calls/1/success': { render: (): React.ReactElement => <QuotingRateCallOneSuccess insuranceType={InsuranceType.auto} /> },

  // Home flow screens
  '/quote/new/home': { render: () => <Redirect to='/quote/new/home/address' /> },
  '/quote/new/home/About': { component: QuotingHomeAbout },
  '/quote/new/home/address': { component: QuotingHomeAddress },
  '/quote/new/home/policy': { render: (): React.ReactElement => <QuotingPolicy insuranceType={InsuranceType.home} /> },
  '/quote/new/home/coverage-levels': { render: (): React.ReactElement => <QuotingCoverageLevels insuranceType={InsuranceType.home} /> },
  '/quote/new/home/coverage-levels/:id/edit': { render: ({ match: { params: { id } } }: CoverageLevelsRouteMatchProps): React.ReactElement => <QuotingCoverageLevel insuranceType={InsuranceType.home} id={id} /> },
  '/quote/new/home/rate-calls/1': { render: (): React.ReactElement => <QuotingRateCallOne insuranceType={InsuranceType.home} /> },
  '/quote/new/home/rate-calls/1/success': { render: (): React.ReactElement =>  <QuotingRateCallOneSuccess insuranceType={InsuranceType.home} /> },
}).map(([
  path,
  props,
]) => {
  return {
    ...props,
    path,
  }
})
/* eslint-enable react/display-name */

const Route404 = (): React.ReactElement => (
  <Route
    path='*'
    render={({ staticContext }): React.ReactElement => {
      if (staticContext) {
        staticContext = {
          ...staticContext,
          status: 404,
        } as ExtendedStaticContext
      }

      return <Http404 />
    }}
  />
)

const Routes: React.FC = (): React.ReactElement => {
  const { pathname } = useLocation()

  return (
    <Switch>
      <Redirect from='/:url*(/+)' to={pathname.slice(0, -1)} />
      <Route path='/' exact={true} component={Home} />
      <Route
        path='/client-self-service'
        render={(): null => {
          if (!isServer) {
            window.location.href =
              'https://www.agentinsure.com/EzLynxCustomerService/insuranceloungegrants'
          }

          return null // eslint-disable-line no-null/no-null
        }}
      />
      <Route path='/about-us' exact={true} component={AboutUs} />
      <Route path='/about-us/careers' exact={true} component={Careers} />
      <Route path='/about-us/our-story' exact={true} component={OurStory} />
      <Route path='/about-us/our-team' exact={true} component={OurTeam} />
      <Route path='/about-us/press' exact={true} component={Press} />
      <Route path='/about-us/press/insurance-lounge-raises-15-million' exact={true} component={PressRelease001} />
      <Route path='/about-us/news' exact={true} component={News} />
      <Route path='/franchising' exact={true} component={Franchising} />
      <Route path='/franchising/inquire' exact={true} component={FranchisingInquire} />
      <Route path='/support' exact={true} component={Support} />
      <Route path='/contact' exact={true} component={Contact} />
      <Route path='/support/ask-a-question' exact={true} component={AskQuestion} />
      <Route path='/carriers' exact={true} component={Carriers} />
      <Route path='/privacy' exact={true} component={Privacy} />
      <Route path='/terms' exact={true} component={Terms} />
      <Route path='/site-map' exact={true} component={SiteMap} />

      <Route path='/blog' exact={true} component={BlogLanding} />
      <Route
        exact={true}
        path='/blog/tags/:slug'
        render={({ match: { params: { slug } } }: BlogListRouteMatchProps): React.ReactElement => <BlogList tagSlug={slug} />}
      />
      <Route
        exact={true}
        path='/blog/posts/:slug'
        render={({ match: { params: { slug } } }: BlogPostRouteMatchProps): React.ReactElement => <BlogPost slug={slug} />}
      />
      <Route
        exact={true}
        path='/blog/posts/preview/:uuid'
        render={({ match: { params: { uuid } } }: BlogPostRouteMatchProps): React.ReactElement => <BlogPost uuid={uuid} />}
      />

      <Route path='/locations/storefronts' exact={true} component={Storefronts} />
      <Route
        path='/locations/storefronts/beaverton'
        exact={true}
        component={StorefrontsBeaverton}
      />
      <Route
        path='/locations/storefronts/eugene'
        exact={true}
        component={StorefrontsEugene}
      />
      <Route
        path='/locations/storefronts/grants-pass'
        exact={true}
        component={StorefrontsGrantsPass}
      />
      <Route
        path='/locations/storefronts/las-vegas'
        exact={true}
        component={StorefrontsLasVegas}
      />
      <Route
        path='/locations/storefronts/medford'
        exact={true}
        component={StorefrontsMedford}
      />

      <Route path='/products' exact={true} component={Products} />
      <Route
        path='/products/group-insurance'
        exact={true}
        component={ProductsGroupInsurance}
      />
      <Route
        path='/products/group-insurance/quote'
        exact={true}
        component={ProductsGroupInsuranceQuote}
      />
      <Route
        path='/products/auto-insurance'
        exact={true}
        component={ProductsAutoInsurance}
      />
      <Route
        path='/products/homeowners-insurance'
        exact={true}
        component={ProductsHomeownersInsurance}
      />
      <Route
        path='/products/life-insurance'
        exact={true}
        component={ProductsLifeInsurance}
      />
      <Route
        path='/products/health-insurance'
        exact={true}
        component={ProductsHealthInsurance}
      />
      <Route
        path='/products/medicare-insurance'
        exact={true}
        component={ProductsMedicareInsurance}
      />
      <Route
        path='/products/disability-insurance'
        exact={true}
        component={ProductsDisabilityInsurance}
      />
      <Route
        path='/products/business-insurance'
        exact={true}
        component={ProductsBusinessInsurance}
      />
      <Route
        path='/products/investments-retirement'
        exact={true}
        component={ProductsInvestmentsRetirement}
      />

      <Route path='/agency-quoter' exact={true} component={AgencyQuoter} />

      <Route path='/suggest-insurance' exact={true} component={SuggestInsurance} />
      <Route
        path='/suggest-insurance/success'
        exact={true}
        component={SuggestInsuranceSuccess}
      />

      <Route path='/quote' exact={true} render={(): React.ReactElement => <Redirect to='/quote/new' />} />
      {dynamicQuoteRoutes.map((route): React.ReactElement => (
        <Route
          key={route.path}
          exact={true}
          {...route}
        />
      ))}

      <Route
        path='/quote/new/health'
        exact={true}
        component={QuotingHealth}
      />
      <Route path='/life-insurance' exact={true} render={(): React.ReactElement => <Redirect to='/quote/new/life' />} />
      <Route
        path='/quote/new/life'
        exact={true}
        component={QuotingLife}
      />
      <Route path='/medicare' exact={true} render={(): React.ReactElement => <Redirect to='/quote/new/medicare' />} />
      <Route path='/medicare-insurance' exact={true} render={(): React.ReactElement => <Redirect to='/quote/new/medicare' />} />
      <Route
        path='/quote/new/medicare'
        exact={true}
        component={QuotingMedicare}
      />

      {(!isProductiony && ( // The following are under development and not yet ready for production
        <>
          <Route
            path='/quote/new/auto/additional-info'
            exact={true}
            component={AutoAdditionalInfo}
          />
          <Route
            path='/quote/new/auto/rate-calls/1.5'
            exact={true}
            component={QuotingRateCallOnePointFive}
          />
          <Route
            path='/quote/new/auto/missing-incident/type-a'
            exact={true}
            component={QuotingMissingIncidentTypeA}
          />
          <Route
            path='/quote/new/auto/missing-incident/type-b'
            exact={true}
            component={QuotingMissingIncidentTypeB}
          />
          <Route
            path='/quote/new/auto/missing-driver'
            exact={true}
            component={QuotingAutoMissingDriver}
          />
          <Route
            path='/quote/new/loading/type-a'
            exact={true}
            component={QuotingRateCallLoadingTypeA}
          />
          <Route
            path='/quote/new/loading/type-b'
            exact={true}
            component={QuotingRateCallLoadingTypeB}
          />
          <Route
            path='/quote/new/pricing'
            exact={true}
            component={QuotingPricing}
          />
          <Route
            path='/quote/new/loading/type-c'
            exact={true}
            component={QuotingRateCallLoadingTypeC}
          />
          <Route
            path='/quote/new/signature'
            exact={true}
            component={QuotingSignature}
          />
          <Route
            path='/quote/new/signature/success'
            exact={true}
            component={QuotingSignatureSuccess}
          />

          <Route404 />
        </>
      )) || undefined}

      <Route404 />
    </Switch>
  )
}

export default Routes
